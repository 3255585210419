const mojoFonts = {
	'ESKlarheitGrotesk-Md': require('./es-klarheit-grotesk/ESKlarheitGrotesk-Md.ttf'),
	'ESKlarheitGrotesk-MdIt': require('./es-klarheit-grotesk/ESKlarheitGrotesk-MdIt.ttf'),
	'ESKlarheitGrotesk-Smbd': require('./es-klarheit-grotesk/ESKlarheitGrotesk-Smbd.ttf'),
	'ESKlarheitGrotesk-SmbdIt': require('./es-klarheit-grotesk/ESKlarheitGrotesk-SmbdIt.ttf'),
	'National2Condensed-Medium': require('./national-2-condensed/National2Condensed-Medium.ttf'),
	'National2Condensed-MediumItalic': require('./national-2-condensed/National2Condensed-MediumItalic.ttf'),
	'National2Condensed-Bold': require('./national-2-condensed/National2Condensed-Bold.ttf'),
	'National2Condensed-BoldItalic': require('./national-2-condensed/National2Condensed-BoldItalic.ttf'),
	'National2Condensed-Extrabold': require('./national-2-condensed/National2Condensed-Extrabold.ttf'),
	'National2Condensed-ExtraboldItalic': require('./national-2-condensed/National2Condensed-ExtraboldItalic.ttf'),
};

export default mojoFonts;
